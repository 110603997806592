import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import Select from 'react-select';
import SelectSearch2 from "./SelectSearch/SelectSearch2";
import "./Add.css";
import MultySelect from "./SelectSearch/multySelect";
import MultySherch from "./SelectSearch/multySherch";
import url from "../../url ";

function Edit() {;
  const [data, setData] = useState("");
  const [post, setPost] = useState({});
  const [selectedOption, setSelectedOption] = useState("זבכ");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [spiner, setSpiner] = useState(false);
  const [disclick, setDisclick] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null);
  const [defaultImage, setDefaultImage] = useState(null);
  const [options, setOptions] = useState([]);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };


  // קוד בשביל סמארטפון
  window.addEventListener('resize', () => {
    setIsMobile(window.innerWidth < 768);
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const table = searchParams.get("table");
  const id = searchParams.get("id");
  let permission = searchParams.get("permission");
  const permissionID = searchParams.get("permissionID");
  if(permission !== 'admin'){
    permission = `${permission}/${permissionID}`
  }

  useEffect(() => {
    const req = async () => {
      setSpiner(true)
      let res = await fetch(`${url}/${permission}/edit-raw/${table}?id=${id}`, {
        credentials: 'include'
      });
      let data = await res.json();
      setData(data);
      data.forEach(async (obj) => {
        let aa;
        // if (Array.isArray(obj.defaultValue)) {
        //   aa = { [obj.raelName]: obj.defaultValue[1] };
        // }
        // else  {
        //   aa = { [obj.raelName]: obj.defaultValue };
        // }
        if (obj.defaultValue === undefined || obj.defaultValue === '') {
          aa = { [obj.raelName]: "" };
        } else if (obj.name === 'photo') {
          const res = await fetch(`${url}/${obj.defaultValue}`, {
            credentials: 'include'
          });
          const blob = await res.blob();
          const photo = URL.createObjectURL(blob);
          setDefaultImage(photo);
        }
        else if (Array.isArray(obj.defaultValue)) {

          if (['club_id', 'store_id', 'recruiter_id', 'client_user_id'].includes(obj.raelName)) {
            aa = { [obj.raelName]: obj.defaultValue[1] };
          }
          else {
            let arr = arrNum(obj.defaultValue)
            console.log(arr);
            aa = { [obj.raelName]: arr };
          }
        }
        else {
          aa = { [obj.raelName]: obj.defaultValue };
        }
        setPost((prevPost) => ({ ...prevPost, ...aa }));
        setOptions(convertArrayToObject(obj.options, obj.defaultValue));
      });
      console.log(data);
      setSpiner(false)
    };
    req();
    // console.log(post);
    // console.log(data);
    // await data.map((obj) => {
    //     setPost({...post, raelName:obj.defaultValue })
    // })
  }, [id, permission, table]);
  const hndelSubmit = async (event) => {
    event.preventDefault();
    setDisclick(true)
    try {
      let newPost = { ...post };
      if (selectedOption && selectedOption['קטגוריית החנות'] === 'אחר') {
        newPost['קטגוריית החנות'] = 'new:' + post['קטגוריית החנות']
      }
      if (selectedOption && selectedOption['צורת התשלום'] === 'אחר') {
        newPost['צורת התשלום'] = 'new:' + post['צורת התשלום']
      }
      const response = await fetch(`${url}/${permission}/edit-raw/${table}?id=${id}`, {
        method: "post",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json", // Specify the content type if sending JSON data.
        },
        body: JSON.stringify(newPost),
      });
      const data = await response.json();
      let resData;
      if (selectedImage) {
        const formData = new FormData();
        formData.append("photo", selectedImage);
        const res = await fetch(`${url}/${permission}/edit-photos/${table}?id=${id}`, {
          method: "post",
          credentials: 'include',
          body: formData
        });
        resData = await res.json();
      }
      if (data.status === "success" && (!resData || resData.status === "success")) {
        console.log( window.location.pathname);
        alert("הרשומה התעדכנה בהצלחה")
        window.history.back();
        // window.location.href = `/admin?table=${table}`;
      }
      else {
        const textError = data.status === "success" ? 'הייתה בעיה בעדכון התמונה' : 'אירעה שגיאה נסה שוב'
        alert(textError)
        console.error("Request failed with status:", response);
      }
    } catch (error) {
      alert("אירעה שגיאה נסה שוב")
      console.error("An error occurred:", error);
    }
    setDisclick(false)
  };
  const  handleSelectChange = (e, raelName) => {
    let obj;
    setSelectedOption({ ...selectedOption, [raelName]: e.target.value });
    obj = { ...post, [raelName]: e.target.value };
    setPost(obj);
  };
  const hndel = (event, raelName, type) => {
    let obj;
    if (type === "checkbox") {
      obj = { ...post, [raelName]: event.target.checked };
    }
    else {
      obj = { ...post, [raelName]: event.target.value };
    }
    console.log(event);
    setPost(obj);
  };
  const hndelSs = (value, raelName) => {
    if (raelName === "club_id" && table === "cards") {
      // console.log("sghftrgregtryt12345678987654321234567");
      let obj;
      obj = { ...post, "קבוצות": [] };
      let obj2 = { ...obj, [raelName]: value };
      setPost(obj2);
    }
    else{
      let obj;
    obj = { ...post, [raelName]: value };
    setPost(obj);
    }
    
  }
  useEffect(() => {
    console.log(post);
  }, [post]);
  return (
    <> 
     <div style={{    display: "flex", justifyContent: "space-between"}}>
        <div  style={{  width: "50%",  height: '50px', display: "inline-block"  }} >
                <button style={{  }} onClick={() => window.history.back()}>X</button>
        </div>
        <div style={{ textAlign: "left", width: "50%", display: "inline-block" }} >
            <img src="/zz.png" alt="סמל" width="150" height="60" />
        </div>
      </div>
    <div className="add">
      <br />
      <form
        style={{ width: isMobile ? "70%" : "25%" }}
        className="pAdd"
        onSubmit={hndelSubmit}
      >
        <h1 className="titelAdd">עריכת רשומה</h1>
        {spiner === true && (<div className="divlod"><div className="loader"></div></div>)}
        {data &&
          data.map((obj) => (
            <div key={obj.name}>

              {obj.type === "select" ?
                <>
                  <label htmlFor="mySelect">{obj.name}</label>
                  <br />
                  <select
                    // style={{overflow: "scroll"}}
                    className="select"
                    id="mySelect"
                    value={selectedOption[obj.raelName] || post[obj.raelName]}
                    onChange={(e) => handleSelectChange(e, obj.raelName)}
                    required={obj.required}
                  // defaultValue={{value: "זכר",label:"זכר"}}

                  >
                    { 
                    !post[obj.raelName]  && 
                    (<option value={null} label="אנא בחר"></option>)
                    }
                    {obj.options.map((v) => {
                        if (v !== 'אחר') {
                          return <option value={v} key={v}>{v}</option>
                        }
                        return null
                      })}
                      {['קטגוריית החנות', 'צורת התשלום'].includes(obj.name) && (<option value={'אחר'} key={'אחר'}>אחר</option>)}
                      </select >
                      {['קטגוריית החנות', 'צורת התשלום'].includes(obj.name) && selectedOption && selectedOption[obj.raelName] === 'אחר' &&
                      (
                      <input required={obj.required} type="text" name="category" onChange={(event) => hndel(event, obj.raelName, obj.type)} value={post[obj.raelName]} />
                      )}
                  </> :
                obj.type === "multy_sherch" ?
                  <MultySherch required={obj.required} name={obj.name} table={obj.table_for_sherch} colmun={obj.colmun_for_sherch} hndel={hndelSs} raelName={obj.raelName} defaultValue={obj.defaultValue} setO/> :

                  obj.type === "searchable" ?
                    <SelectSearch2 required={obj.required} name={obj.name} table={obj.table_for_sherch} colmun={obj.colmun_for_sherch} hndel={hndelSs} raelName={obj.raelName} defaultValue={obj.defaultValue instanceof Array ? obj.defaultValue[0]: ""} /> :
                    obj.type === "multy_select" ?
                      // post.club_id.length>10?
                      // console.log(post.club_id.length<1):
                      < MultySelect required={obj.required} clubId={post.club_id} name={obj.name} hndel={hndelSs} raelName={obj.raelName} defaultValue={obj.defaultValue} options={options} /> :
                      obj.type === "photo" ?
                        <>
                          <label htmlFor="clubName">תמונה</label>
                          <br />
                          <div>
                            <input type="file" accept="image/*" onChange={handleImageChange} required={obj.required}/>
                            {selectedImage ? (
                                <img src={URL.createObjectURL(selectedImage)} alt="Selected" width="300" />
                              ) : (
                                defaultImage && <img src={defaultImage} alt="Default" width="300" />
                              )}
                          </div>
                        </> :
                      (
                        <>

                          <label htmlFor="clubName">{obj.name}</label>
                          <br/>
                          <input
                            type={obj.type}
                            id="clubName"
                            name="clubName"
                            onChange={(event) => hndel(event, obj.raelName, obj.type)}
                            value={post[obj.raelName]}
                            maxLength={obj.maxlength}
                            // checked={obj.defaultValue}
                            defaultChecked={obj.defaultValue}
                            required={obj.required && obj.type !== "checkbox"}
                          />
                        </>)
              }
            </div>

          ))}
          <br />
        {disclick === true ? <button type="submit" disabled style={{ backgroundColor: "gray" }}>בשליחה...</button> :
          <button type="submit" >שמור</button>
        }

      </form>
    </div>
    </>
  );
}
function convertArrayToObject(arr, difoltValue ) {
  // יצירת מערך ריק לכל האובייקטים
  let result = [];

  // עבר בכל תת מערך שבמערך הנתון והמרתו לאובייקט
  if (!arr) {
    return result
  }
  for (let i = 0; i < arr.length; i++) {
    let subArray = arr[i];
    let label = subArray["name"]// הסרת רווחים משני הצדדים של השם
    let value = subArray.id
    let checked;
    // console.log(d);
    if (Array.isArray(difoltValue)) {
      for(let i of difoltValue){

        if (i[1]=== label){
          checked = true
          break
        }
        else{
          checked = false
        }
      }
    }
    // let checked = subArray.checked

    console.log(arr);

    //   יצירת אובייקט חדש והוספתו למערך
    result.push({ value, label, checked });
  }

  return result;
}
export default Edit;

function arrNum(arr) {
  // יצירת מערך חדש לאחסון המספרים
  let num = [];

  // עבירה על כל מערך במערך המקורי
  for (let i = 0; i < arr.length; i++) {
      // בדיקה אם האיבר הוא מערך
      if (Array.isArray(arr[i])) {
          // עבירה על המערך הפנימי
          for (let j = 0; j < arr[i].length; j++) {
              // בדיקה אם האיבר במערך הפנימי הוא מספר
              if (typeof arr[i][j] === 'number') {
                  // הוספת המספר למערך החדש
                  num.push(arr[i][j]);
              }
          }
      }
  }

  // החזרת מערך שמכיל רק את המספרים
  return num;
}